import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";
import { ehProducao } from "@/services/ambiente";
import { createChat } from "@n8n/chat";
import { openDrawer } from "@/store/reducers/menu";
import {
  setInfoDialog,
  setInfoDialogConfirmacao,
  setInfoDialogFormatoRelatorio,
} from "@/store/reducers/geral";
import Spinner from "@/components/Spinner";
import Toaster from "@/components/Toaster";
import DialogFormatoRelatorio from "@/components/DialogFormatoRelatorio";
import Dialog from "@/components/Dialog";
import Drawer from "./Drawer";
import Header from "./Header";
import navigation from "@/menu-items-parceiro";
import DialogConfirmacao from "@/components/DialogConfirmacao";
import Breadcrumbs from "@/components/@extended/Breadcrumbs";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const geral = useSelector((state) => state.geral);
  const { drawerOpen } = useSelector((state) => state.menu);
  const [carregar, setCarregar] = useState(false);
  const user = useSelector((state) => state.user);

  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG, dispatch]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    const loadCahtN8n = async () => {
      const companyEnabled =
        user?.companyBase?.idCompany === 1002 ||
        user?.companyBase?.idCompany === 1455 ||
        user?.companyBase?.idCompany === 1497 ||
        user?.companyBase?.idCompany === 1909;
      if (ehProducao() && companyEnabled) {
        createChat({
          webhookUrl:
            "https://n8n.quitabank.com.br/webhook/df6d210e-5f5d-4c12-b338-42912b0b49ff/chat",
          defaultLanguage: "ptBR",
          initialMessages: [
            "Olá parceiro! Bem-vinda(o) a Quita! Como posso ajudar?",
          ],
          i18n: {
            ptBR: {
              title: "Bem-vindo a Quita!",
              subtitle: "",
              footer: "",
              getStarted: "Nova conversa",
              inputPlaceholder: "Escreva aqui sua dúvida",
            },
          },
        });
      }
    };

    if (carregar && user?.companyBase?.idCompany) {
      setCarregar(false);
      loadCahtN8n();
    }
  }, [carregar, dispatch, user?.companyBase?.idCompany]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        <Breadcrumbs
          navigation={navigation}
          title
          titleBottom
          card={false}
          divider={false}
        />
        <Outlet />
      </Box>
      {geral?.infoDialog?.open && (
        <Dialog {...{ infoDialog: geral.infoDialog, setInfoDialog }} />
      )}
      <Spinner {...{ showSpinner: geral.showSpinner }}></Spinner>
      <Toaster {...{ infoToaster: geral.infoToaster }}></Toaster>
      {geral?.infoDialogConfirmacao.open && (
        <DialogConfirmacao
          {...{
            infoDialogConfirmacao: geral.infoDialogConfirmacao,
            setInfoDialogConfirmacao,
          }}
        />
      )}
      {geral?.infoDialogFormatoRelatorio.open && (
        <DialogFormatoRelatorio
          {...{
            infoDialogFormatoRelatorio: geral.infoDialogFormatoRelatorio,
            setInfoDialogFormatoRelatorio,
          }}
        />
      )}
    </Box>
  );
};

export default MainLayout;
