import { createContext, useContext } from "react";
import { post as postApiClient, get as getApiClient } from "@/services/api";
import {
  post as postApiPartner,
  get as getApiPartner,
} from "@/services/apiParceiro";
import { useDispatch } from "react-redux";
import { activeSpinner, cancelSpinner } from "@/store/reducers/geral";
import { setInfoDialog } from "@/store/reducers/geral";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const dispatch = useDispatch();

  const postClient = async (url, data) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        dispatch(activeSpinner());
        const result = await postApiClient(url, data);
        dispatch(cancelSpinner());

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result);
          return;
        }
        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        let message =
          "Ocorreu um erro ao consultar os dados. Por favor atualize a página e tente novamente!";
        if (e.status === 400 && e.response?.data?.error) {
          message = e.response.data.error;
        }
        dispatch(
          setInfoDialog({
            open: true,
            descricao: message,
          })
        );
        reject(e);
      }
    });
    return promise;
  };

  const getClient = async (url) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        dispatch(activeSpinner());
        const result = await getApiClient(url);
        dispatch(cancelSpinner());

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result);
          return;
        }
        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        let message =
          "Ocorreu um erro ao consultar os dados. Por favor atualize a página e tente novamente!";
        if (e.status === 400 && e.response?.data?.error) {
          message = e.response.data.error;
        }
        dispatch(
          setInfoDialog({
            open: true,
            descricao: message,
          })
        );
        reject(e);
      }
    });
    return promise;
  };

  const postPartner = async (url, data) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        dispatch(activeSpinner());
        const result = await postApiPartner(url, data);
        dispatch(cancelSpinner());

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result);
          return;
        }
        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        let message =
          "Ocorreu um erro ao consultar os dados. Por favor atualize a página e tente novamente!";
        if (e.status === 400 && e.response?.data?.error) {
          message = e.response.data.error;
        }
        dispatch(
          setInfoDialog({
            open: true,
            descricao: message,
          })
        );
        reject(e);
      }
    });
    return promise;
  };

  const getPartner = async (url) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        dispatch(activeSpinner());
        const result = await getApiPartner(url);
        dispatch(cancelSpinner());

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result);
          return;
        }
        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        let message =
          "Ocorreu um erro ao consultar os dados. Por favor atualize a página e tente novamente!";
        if (e.status === 400 && e.response?.data?.error) {
          message = e.response.data.error;
        }
        dispatch(
          setInfoDialog({
            open: true,
            descricao: message,
          })
        );
        reject(e);
      }
    });
    return promise;
  };

  return (
    <ApiContext.Provider
      value={{ postClient, getClient, postPartner, getPartner }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  return useContext(ApiContext);
};
