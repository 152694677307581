import moment from "moment";
import { formataCpfCnpj } from "./document";

export function formatMonetario(valor) {
  valor = valor || 0;
  if (typeof valor == "string") {
    valor = parseFloat(valor);
  }
  return valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
}
export function apenasNumeros(str) {
  return (str || "").toString().replace(/\D/g, "");
}
export function apenasLetrasNumeros(str) {
  return (str || "").toString().replace(/[^a-zA-Z0-9]/g, "");
}
export function apenasMonetario(str) {
  return (str || "").toString().replace(/[^01234567890,.]/g, "");
}

export function aoPressionarEnter(event, executar) {
  if (event.key === "Enter") {
    executar();
  }
}

export const formatPhoneNumber = (valor) => {
  if (!valor) return "";

  const numero = valor.replace(/\D/g, "");

  if (numero.length <= 2) {
    return `(${numero}`;
  } else if (numero.length <= 7) {
    return `(${numero.slice(0, 2)}) ${numero.slice(2)}`;
  }
  return `(${numero.slice(0, 2)}) ${numero.slice(2, 7)}-${numero.slice(7, 11)}`;
};

export const getRandomString = (size) => {
  var string = "";
  var caracteres =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < size; i++) {
    string += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return string;
};

export async function sleep(msec) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}

export function validaNumeroTelefone(phone) {
  const regex = /^(\(?\d{2}\)?\s?)?(9\d{4}-?\d{4})$/;
  return regex.test(phone);
}

export function validarEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const formataTipoChave = (chave) => {
  let chaveFormatada = "";

  switch (chave) {
    case "CpfCnpj":
      chaveFormatada = "CPF/CNPJ";
      break;
    case "Email":
      chaveFormatada = "E-mail";
      break;

    default:
      chaveFormatada = chave;
      break;
  }

  return chaveFormatada;
};
export const formataChave = (chave, tipo) => {
  let chaveFormatada = "";

  switch (tipo) {
    case "CpfCnpj":
      chaveFormatada = formataCpfCnpj(chave);
      break;
    case "Telefone":
      chaveFormatada = formatPhoneNumber(chave);
      break;

    default:
      chaveFormatada = chave;
      break;
  }

  return chaveFormatada;
};

export function estaDentroDoPeriodoDeDias(data_inicial, data_final, qtd_dias) {
  const diferencaDias = moment(data_final).diff(data_inicial, "days");

  return diferencaDias <= qtd_dias;
}

export const formataValorSimulador = (value) => {
  return parseFloat(parseFloat(value || 0).toFixed(2));
};

export const formatarPlaca = (placa) => {
  let value = placa.toUpperCase().replace(/[^A-Z0-9]/g, "");

  if (value.length > 3 && value.length <= 7) {
    // Formato Mercosul (AAA9A99)
    if (/^[A-Z]{3}[0-9]{1}[A-Z]{1}/.test(value)) {
      value = value.replace(
        /^([A-Z]{3})([0-9]{1})([A-Z]{1})([0-9]{2})?$/,
        "$1$2$3$4"
      );
    } else {
      // Formato Antigo (AAA-9999)
      value = value.replace(/^([A-Z]{3})([0-9]{0,4})$/, "$1-$2");
    }
  }

  return value;
};

export const formataAmount = (value) => {
  value = `${value || 0}`;
  return parseFloat(value.replace(".", "").replace(",", "."));
};
