import { useSelector } from "react-redux";
import { getUrlLogo } from "../services/whitelabel";
export default function LogoFundoEscuro(props) {
  const user = useSelector((state) => state.user);

  const inicio = () => {
    window.location.href = getUrlLogo();
  };

  return (
    <>
      {user?.logos?.darkBackground && (
        <img
          onClick={inicio}
          src={user.logos.darkBackground}
          style={{ maxWidth: "100px" }}
          alt=""
          {...props}
        />
      )}
    </>
  );
}
